import React from "react"
import OptimizedImage from "../../../utils/optimizedImage"
import { Button } from "../../../atoms/Button"
import {
  Wrapper,
  Col,
  Icon,
  Heading,
  Subhead,
} from "../styles/ColumnHeaders.styled"

const ColumnsHeaders = ({ component }) => {
  const columns = [{}, ...component]

  return (
    <Wrapper>
      {columns.map(col => (
        <Col>
          {col?.icons && (
            <Icon>
              <OptimizedImage
                image={col?.icons[0]?.primaryImage?.gatsbyImageData}
                alt={
                  col?.icons[0]?.primaryImage?.description ||
                  col?.icons[0]?.primaryImage?.title ||
                  "icon"
                }
              />
            </Icon>
          )}
          {col?.heading && <Heading>{col?.heading}</Heading>}
          {col?.subheading?.subheading && (
            <Subhead>{col?.subheading?.subheading}</Subhead>
          )}
          {col?.callsToAction && (
            <Button
              ctaLink={col?.callsToAction[0].link}
              ctaVariant={col?.callsToAction[0].variant}
              {...col?.callsToAction[0]}
            >
              {col?.callsToAction[0]?.label}
            </Button>
          )}
        </Col>
      ))}
    </Wrapper>
  )
}

export default ColumnsHeaders
